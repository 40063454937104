import React from "react"
import { Link, withPrefix } from 'gatsby'
import styles from './styles.module.scss'
import Image from "../Image";
import arrowSrc from '../../assets/img/arrow-right.svg'

const ImageLinkCard = ({ title, image, link }) => (
  <div className={styles.imageLinkCardWrapper}>
    
    <Link to={withPrefix(link)} className={styles.imageLinkCard}>
      
      <div className={styles.image}>
        <Image imgName={image} />
      </div>
      
      <div className={styles.description}>
        <h3 className={styles.title}>{title}</h3>
        
        <img src={arrowSrc} className={styles.arrow} alt="arrow"/>
      </div>
      
    </Link>
    
  </div>
);

export default ImageLinkCard
