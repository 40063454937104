import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Layout from "../../components/Layout"
import SEO from '../../components/seo'
import PropTypes from "prop-types"
import ImageLinkCard from "../../components/ImageLinkCard"
import GridWrapper from "../../components/GridWrapper"
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import styles from './styles.module.scss'

const StudentGuide = ( {data} ) => {
  const studentGuideCategoryPages = data.allCategoryPage.edges;
  
  const crumbs = [
    {pathname: "/", crumbLabel: "Home"},
    {pathname: "/student-guide", crumbLabel: "Guide"}
  ];
  
  return (
    <Layout hideSidebar={true}>
      <SEO title="Academic Info | Student Guide" />
      
      <CustomBreadcrumbs crumbs={crumbs}/>
      
      <section className={styles.studentGuide}>
        <h1 className={styles.pageTitle}>
          AcademicInfo Student Guide - Resources for Prospective and Current Students
        </h1>
        
        <p className={styles.pageText}>
          From accreditation to test preparation, financial aid to our college guide, or simply looking to get some general information on getting started with online education, then this page is your gateway to all things academia related.
        </p>
        
        <h2 className={styles.sectionTitle}>
          Student Guide
        </h2>
        
        <GridWrapper columns={3} className={styles.cardsWrapper}>
          {studentGuideCategoryPages.map( (page, index) => (
            <ImageLinkCard
                image={page.node.image}
                title={page.node.title}
                link={page.node.path}
                key={index}
            />
          ))}
        </GridWrapper>
      </section>
    </Layout>
  );
};

StudentGuide.propTypes = {
  data: PropTypes.object
};

export default () => (
  <StaticQuery
    query={graphql`
      query StudentGuideQuery {
        allCategoryPage(filter: {sections: {elemMatch: {section: {eq: "study-guide"}}}}) {
          edges {
            node {
              title
              path
              image
            }
          }
        }
      }
    `}
    render={ (data) => <StudentGuide data={data} /> }
  />
)
