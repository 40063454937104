import React from "react"
import styles from './styles.module.scss'
import classNames from 'classnames'

const GridWrapper = ({ children, columns }) => {
  
  return (
    <div className={classNames(styles.gridWrapper, columns === 2 && styles.twoColumns)}>
      {children}
    </div>
  )
};

export default GridWrapper
