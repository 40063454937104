import React from "react"
import { withPrefix } from 'gatsby'
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

const CustomBreadcrumbs = ({ crumbs, path, title, section, crumbLabel }) => {
  const homeCrumb = {pathname: "/", crumbLabel: "Home"};
  
  // Some templates overrides.
  switch (section) {
    case "study-guide":
      crumbs = [
        homeCrumb,
        {pathname: "/student-guide", crumbLabel: "Guide"},
        {pathname: withPrefix(path), crumbLabel: title}
      ];
      break;
    case 'degrees' :
      crumbs = [
        homeCrumb,
        {pathname: "/eddist.html", crumbLabel: "Degrees"},
        {pathname: withPrefix(path), crumbLabel: title}
      ];
      break;
    default :
      if (!crumbs) {
        crumbs = [
          homeCrumb,
          {pathname: withPrefix(path), crumbLabel: title}
        ];
      }
      break;
  }
  
  return (
      <Breadcrumb crumbs={crumbs} crumbLabel={!!crumbLabel ? crumbLabel : undefined} />
  )
};

export default CustomBreadcrumbs
